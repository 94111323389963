/* global styles */
* {
  box-sizing: border-box;
  font-family: "Fira Sans";
}
a {
  color: #000;
}
a:hover {
  text-decoration: none;
  color: currentColor;
}
p {
  margin-bottom: 0;
}
textarea {
  resize: none;
}
b,
strong,
.font-weight-bold {
  font-weight: 600 !important;
}
.text_underlined {
  text-decoration: underline !important;
}
.bolder {
  font-weight: 800;
}
.btn,
.form-control {
  box-shadow: none !important;
  outline: none !important;
}
.bg_black {
  background-color: black;
}
.bg_light {
  background-color: #fff7ef;
}
.bg_peach {
  background-color: #fff1e5;
}
.bg_primary {
  background-color: #0d7680;
}
.bg_secondary {
  background-color: #990f3d;
}
.text_primary {
  color: #0d7680 !important;
}
.text_secondary {
  color: #990f3d;
}
.text_peach {
  color: #f2dfce !important;
}
.text_blue {
  color: #5686ff !important;
}
.text_faded {
  color: #aaa;
}
.primary_btn {
  color: #fff !important;
  background-color: #0d7680;
}
.primary_btn:hover {
  background-color: #035962;
}
.btn_light {
  background-color: #fff7ef;
  color: #000 !important;
}
.btn_light:hover {
  background-color: #fff1e5;
}
.green_tag {
  background-color: #fff;
  color: #0d7680;
  border: 2px solid #0d7680;
  border-radius: 50px;
  padding: 2px 8px 2px 8px;
  background-color: #e8fdff;
}
#bottom_nav {
  position: fixed;
  z-index: 3;
  background-color: #fff;
  left: 50%;
  transform: translateX(-50%);
  width: 250px;
  border: 2px solid #5686ff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
#bottom_nav .btn-sm {
  font-size: 12px;
}
.clamp {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.5em;
}
.clamp2 {
  -webkit-line-clamp: 2;
  height: 3em;
}

/* homepage */
#home_motto {
  font-size: 5.5rem;
}
#awareness_page_carousel .carousel-control-prev {
  left: 0;
  width: 20px;
}
#awareness_page_carousel .carousel-control-next {
  right: 0;
  width: 20px;
}

/* pb junior homepage */
#home_input {
  background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="220" height="48"><text x="12" y="30" style="font: bold 20px Arial;">positionbuilding.com/</text></svg>')
    no-repeat;
  border: 1px solid #ccc;
  border-radius: 50px;
  box-sizing: border-box;
  font: 20px "Arial";
  height: 48px;
  padding-left: 220px;
  width: 450px;
  display: inline-block;
}
.home_footer_link {
  color: #000;
}
.home_footer_link:hover {
  color: #777;
}
.home_footer_socials:hover {
  transition: ease 0.2s;
  transform: scale(1.3);
}
.dropup_icon {
  transition: 0.3s ease;
}
.dropup_opened {
  transform: rotate(180deg);
}

/* profile detail page styles */
.post_box {
  background-color: #fff;
  border: 1px solid #999;
  border-right: 2px solid #000;
  border-bottom: 2px solid #000;
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.1);
  position: relative;
}
.post_box:hover {
  animation: cardVibrate 0.5s ease 0s 1;
  box-shadow: 0 4px 6px 2px rgba(0, 0, 0, 0.2);
}
.post_box::before {
  content: "";
  position: absolute;
  top: -8px;
  left: -8px;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #999;
  z-index: -1;
}
#mask {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 4;
  top: 0;
  left: 0;
}
#filter_section {
  position: fixed;
  width: 0;
  max-width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 5;
  transition: 0.2s;
  background-color: #fff;
  border-right: 1px solid #aaa;
  overflow: hidden;
}
#actions_panel {
  position: fixed;
  width: 0;
  max-width: 100%;
  height: 100vh;
  top: 0;
  right: 0;
  z-index: 5;
  transition: 0.2s;
  background-color: #fff;
  border-right: 1px solid #aaa;
  overflow-x: hidden;
}

/* post detail page styles */
.post_details_side_btns {
  font-size: 14px;
  border: 1px solid #ddd !important;
  background: transparent;
}
.post_details_side_btns:hover {
  background-color: #fff1e5;
}
#blur_cont {
  position: relative;
  margin-top: 20px;
}
#blur_mask {
  position: absolute;
  top: 0;
  background-color: rgba(255, 247, 239, 0.5);
  backdrop-filter: blur(4px);
}
#postImgCarousel .carousel-control-prev,
#postImgCarousel .carousel-control-next {
  border: none;
  background-color: rgba(0, 0, 0, 0.4);
  width: 30px;
}
#postImgCarousel img {
  width: 100%;
  aspect-ratio: 1.77;
  object-fit: contain;
}

@keyframes cardVibrate {
  0%,
  100% {
    transform: translateX(0);
  }
  20%,
  60% {
    transform: translateX(-8px);
  }
  40%,
  80% {
    transform: translateX(8px);
  }
}

/* registration page */
.usertype_btn {
  border: 1px solid #b8c0c0;
  background-color: #fff;
  color: #868686;
  font-weight: bold;
}
.usertype_radio:checked + .usertype_btn {
  border: 1px solid #0d7680;
  background-color: #defff3;
  color: #0d7680;
  font-weight: bold;
}

@media (max-width: 575px) {
  #home_input {
    background: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="220" height="30"><text x="12" y="19" style="font: bold 16px Arial;">positionbuilding.com/</text></svg>')
      no-repeat;
    font: 16px "Arial";
    height: 30px;
    padding-left: 180px;
    width: 270px;
  }
  #home_motto {
    font-size: 3rem;
  }
}
@media (min-width: 992px) {
  #bottom_nav {
    width: 400px;
  }
  #bottom_nav .btn-sm {
    font-size: 14px;
  }

  #homepage_carousel .triple_slide .carousel-item.active,
  .triple_slide .carousel-item-next,
  .triple_slide .carousel-item-prev {
    display: flex;
  }
  #homepage_carousel .triple_slide .carousel-item-right.active,
  .triple_slide .carousel-item-next {
    transform: translateX(33.3333%);
    -webkit-transform: translateX(33.3333%);
  }
  #homepage_carousel .triple_slide .carousel-item-left.active,
  .triple_slide .carousel-item-prev {
    transform: translateX(-33.3333%);
    -webkit-transform: translateX(-33.3333%);
  }
  #homepage_carousel .triple_slide .carousel-item-right,
  .triple_slide .carousel-item-left {
    transform: translateX(0);
    -webkit-transform: translateX(0);
  }
  #homepage_carousel .carousel-control-prev {
    width: 20px;
  }
  #homepage_carousel .carousel-control-next {
    width: 20px;
  }
}

.dangerous h1 {
  font-size: 1rem;
}

.asdf {
  display: flex;
  width: 100vw;
  justify-content: center;
}

button {
  all: unset;
  cursor: pointer;
}

.profile-btn-disabled {
  pointer-events: none;
  opacity: 0.5;
  cursor: pointer;
}

.recaptcha {
  text-align: center;
  justify-content: center;
  align-items: center;
  display: flex;
}

@media screen and (max-width: 768px) {
  .profile-main {
    flex-direction: column;
  }
}

/* css for landing */
/* * {
  box-sizing: border-box;
  font-family: "Manrope", Arial, Helvetica, sans-serif;
  color: #fff;
}

body > div {
  z-index: -5;
} */

.btn,
.form-control {
  box-shadow: none !important;
  outline: none !important;
}
.community_brands {
  font-family: "Montserrat", Arial, Helvetica, sans-serif;
  letter-spacing: 0.05em;
}
.text_red {
  color: #ff0000 !important;
}
.bg_black {
  background-color: #121212;
}
.bg_dark {
  background-color: #1e1e1e;
}
.letter_spaced {
  letter-spacing: 0.1em;
  font-weight: 600;
}
.heading {
  letter-spacing: 0.05em;
  line-height: 1.3em;
}
.social_texts:disabled {
  font-weight: 600;
  letter-spacing: 0.1em;
  opacity: 1;
}
.cta_btn {
  color: #fff !important;
  border-radius: 100px;
  background-color: rgba(255, 0, 0, 0.8);
  font-weight: 600;
  padding-top: 10px;
  padding-bottom: 10px;
  letter-spacing: 0.1em;
}
.cta_btn:hover {
  background-color: rgba(255, 0, 0, 1);
}
.rounded_card {
  background-color: rgba(242, 243, 247, 0.1);
  border-radius: 8px;
}
.faq_btn {
  color: #fff !important;
  text-align: left;
  font-weight: 600;
  padding: 0;
}
.numbers_tiles {
  width: 85%;
  /* height: 100%; */
  aspect-ratio: 1;
}
.red_outline_btn {
  border-radius: 50px;
  border: 2px solid #ff0000;
  background-color: rgba(255, 0, 0, 0);
  color: #fff !important;
  font-weight: 600;
  letter-spacing: 0.15em;
}
.red_outline_btn:hover {
  background-color: rgba(255, 0, 0, 0.3);
}
.carousel_img {
  width: 100%;
  border-radius: 0 8px 8px 0;
}
.carousel_btns {
  background-color: #121212;
  opacity: 1 !important;
  height: 50px;
  border-radius: 50%;
  top: calc(50% - 24px);
  transform: translateY(-50%);
  width: 50px;
}
.carousel_btns:hover {
  background-color: #444;
}
.carousel-control-prev {
  left: -25px;
}
.carousel-control-next {
  right: -25px;
}
.navbar .nav-link {
  color: #fff !important;
}
.navbar-toggler {
  outline: none !important;
  border: none !important;
}
.modal-backdrop {
  background-color: #1e1e1e;
}
body.modal-open nav,
body.modal-open section,
body.modal-open footer {
  -webkit-filter: blur(8px);
  -moz-filter: blur(8px);
  -o-filter: blur(8px);
  -ms-filter: blur(8px);
  filter: blur(8px);
}
#inviteModal .modal-content {
  background-color: #121212 !important;
  border-radius: 16px;
}
#inviteModal .btn {
  background-color: rgba(242, 243, 247, 0.1);
  color: #fff !important;
  letter-spacing: 0.05em;
  border-radius: 50px;
}
#inviteModal .btn:hover {
  background-color: rgba(242, 243, 247, 0.3);
}
.collapsible_sections {
  border-radius: 4px;
  border: 1px solid grey;
  cursor: pointer;
}
.community_heading {
  word-spacing: 0.4rem;
}

@media (max-width: 767px) {
  p,
  a,
  .btn-lg {
    font-size: 0.98em;
  }
  h1,
  h2 {
    font-size: 1.5em;
  }
  h3,
  h4 {
    font-size: 1.2em;
  }
  h5 {
    font-size: 1.05em;
  }
  .carousel_img {
    border-radius: 8px 8px 0 0;
  }
  .carousel_btns {
    height: 40px;
    width: 40px;
  }
}

.landing-main * {
  color: #fff;
}
