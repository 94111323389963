@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@900&family=Public+Sans:wght@400;700&display=swap');


.scrollbar-hide::-webkit-scrollbar {
    display: none;
}

.webfont {
    font-family: 'Public Sans', sans-serif;
    font-weight: 400;
    letter-spacing: -0.7px;
}
.webfont-bold {
    font-family: 'Public Sans', sans-serif;
    font-weight: 700;
}

.heading-font {
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 900;
}

.widget-animation-container,
.widget-animation-container * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  outline: none;
  text-decoration: none;
  font-family: "Roboto", sans-serif;
  z-index: 1035;
  -webkit-tap-highlight-color: transparent;
}

.widget-icon {
  z-index: 1036;
}

.widget-animation-container {
  width: 8.5rem;
  height: 8.5rem;
  border-radius: 4.5rem;

  position: fixed;
  bottom: 0.15rem;
  right: 0.15rem;

  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-animation {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 5rem;
  background-color: rgba(255, 0, 0, 0.25);

  animation-name: expand;
  animation-duration: 2.15s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  transform: translateZ(0);
}

@keyframes expand {
  25% {
    width: 6.5rem;
    height: 6.5rem;
  }

  65% {
    width: 8.5rem;
    height: 8.5rem;
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

.widget-icon {
  width: 4.25rem;
  height: 4.25rem;
  border-radius: 2.5rem;
  background-color: #ff0000cc;

  position: absolute;
  top: 25%;
  left: 25%;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.widget-icon::before {
  content: "1";
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 0;
  left: 0;

  width: 1.35rem;
  height: 1.35rem;
  border-radius: 1rem;
  border: 2px solid white;
  background-color: #ff0000cc;
}

.widget-container {
  position: absolute;
  bottom: 6.5rem;
  right: 1.85rem;
  width: 22.5rem;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 15px lightgray;
}

.widget {
  flex: 1;
  display: flex;
  overflow: hidden;
  background-color: white;
  color: darkslategray;
}

.widget-call,
.widget-mail {
  min-width: 100%;
  /* min-height: 23.5rem; */
}

.widget-call {
  display: none;
}

.widget-header {
  height: 5rem;
  padding: 2.25rem;
  line-height: 1.65rem;
  font-size: 1.15rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff0000cc;
  color: white;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem 1.85rem;
}

.widget .form p {
  color: darkslategray;
}

.select-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.widget select {
  width: 45%;
  padding: 0.65rem 0.15rem;
  font-size: 0.95rem;
  border: none;
  border-radius: 5px;
  background-color: white;
  color: darkslategray;
  box-shadow: 0 0 10px lightgray;
  border: 2px solid lightgray;
}

.widget select option {
  color: darkslategray;
}

.widget input,
.widget textarea {
  padding: 0.35rem 1.75rem;
  font-size: 1rem;
  border: none;
  border-radius: 5px;
  box-shadow: 0 0 5px lightgray;
  border: 2px solid lightgray;
  color: darkslategray;
}

.button-container {
  width: 100%;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-top: 1px solid darkgray;
}

.button-container button {
  width: 70%;
  height: 65%;
  background-color: #ff0000cc;
  color: white;
  outline: none;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  font-size: 1.15rem;
  cursor: pointer;
}

.button-container button.none {
  display: none;
}

.button-container button:hover {
  background-color: lightseagreen;
}

.disclaimer {
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.disclaimer p {
  font-size: 0.85rem;
  color: darkslategray;
  text-align: center;
  padding: 0.15rem 1.15rem;
}

.widget-footer {
  background-color: white;
}

.widget-footer .icons {
  height: 3.5rem;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-top: 1px solid darkgray;
}

.widget-footer .icons .icon {
  width: 2.25rem;
  height: 2.25rem;
  background-color: lightgray;
  border-radius: 5px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
}

.widget-footer .icons .call-icon {
  background-color: lightseagreen;
}

.widget-footer .icons .mail-icon {
  background-color: yellow;
}

.widget-footer .icons .whatsapp-icon {
  background-color: #25d366;
}

.widget-container.none,
.widget-animation.none,
.widget-icon.none {
  opacity: 0;
}

.widget-animation-container > div {
  transition: opacity 0.35s ease-in-out;
}

.widget-footer {
  border-top: 1px solid darkgray;
}

.widget-footer > p {
  text-align: center;
  font-size: 1rem;
  color: darkslategray;
  padding: 1rem;
}

.widget-footer.none {
  display: none;
}

.widget-footer.error {
  display: block;
}

.widget-footer.error .icons {
  display: none;
}

@media screen and (max-width: 500px) {
  .widget-animation-container {
    bottom: -1rem;
    right: -1.85rem;
  }

  .widget-container {
    width: 100vw;
    height: 100vh;
    position: absolute;
    bottom: 1rem;
    right: 1.85rem;
  }

  .g-recaptcha {
    display: none;
  }
}

.widget-loader {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid #3498db;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

.widget-loader.none {
  display: none;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

